<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogQuestion"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Thêm câu hỏi</span
              >
              <span class="headline" v-else>Sửa câu hỏi</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogQuestion = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="1" class="p-0 text-center pt-2"
                  ><span class="text-h5">Q{{ question_number }}</span></v-col
                >
                <v-col cols="7" class="p-0 m-0" id="questionCk">
                  <EditorBalloon
                    min-height="56"
                    v-model="title_input"
                    placeholder="Nhập tiêu đề câu hỏi"
                  ></EditorBalloon>
                </v-col>
                <v-col cols="4" class="p-0 m-0 pl-1" id="questionType">
                  <v-select
                    placeholder="Chọn loại câu hỏi"
                    outlined
                    :items="question_types"
                    item-value="value"
                    item-text="label"
                    v-model="type_input"
                  ></v-select>
                </v-col>
                <template v-if="type_input !== null">
                  <v-col cols="1" class="p-0 text-right"></v-col>
                  <v-col cols="11" class="p-0 d-flex">
                    <span class="text-h6 text--darken-5">Bắt buộc trả lời</span>
                    <v-switch
                      class="mt-0 pt-0 pl-1"
                      v-model="is_required_input"
                      flat
                      :label="is_required_input ? 'Yes' : 'No'"
                    ></v-switch>
                  </v-col>
                </template>
                <template
                  v-if="
                    type_input !== null &&
                    (type_input === 2 || type_input === 4)
                  "
                >
                  <v-col cols="1" class="p-0"></v-col>
                  <v-col cols="7" class="p-0 text-right pr-2 pt-1">
                    <p class="text-h6">Chọn mẫu đáp án</p>
                  </v-col>
                  <v-col cols="3" class="p-0 pl-1">
                    <v-select
                      placeholder="Select type"
                      dense
                      outlined
                      :items="option_groups"
                      item-value="id"
                      item-text="name"
                      v-model="option_group_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="1" class="p-0"></v-col>
                </template>
                <v-col cols="12" class="p-0" v-if="type_input !== null">
                  <!-- MultipleChoice-->
                  <MultipleChoiceType
                    v-if="type_input === value_question_types.MULTIPLE_CHOICE"
                    :option="optionAnswerMultipleChoice"
                    @setOptionAnswer="(e) => (optionAnswerMultipleChoice = e)"
                  />

                  <!-- SingleChoice-->
                  <SingleChoiceType
                    v-if="type_input === value_question_types.SINGLE_CHOICE"
                    :option="optionAnswerSingleChoice"
                    @setOptionAnswer="(e) => (optionAnswerSingleChoice = e)"
                  />

                  <!-- StarRating-->
                  <template
                    v-if="type_input === value_question_types.STAR_RATING"
                  >
                    <v-row>
                      <v-col cols="1" class="p-0 text-right">
                        <p class="text-h6 mt-1 text--darken-5">Scale</p>
                      </v-col>
                      <v-col cols="2" class="p-0 pl-3 text-right">
                        <v-select
                          dense
                          outlined
                          :items="[2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          v-model="scale_input"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
                <template
                  v-if="
                    type_input !== null &&
                    (type_input === value_question_types.SINGLE_CHOICE ||
                      type_input === value_question_types.MULTIPLE_CHOICE)
                  "
                >
                  <v-col cols="1" class="p-0"></v-col>
                  <v-col cols="11" class="p-0 text-left">
                    <v-checkbox
                      v-model="other_text_option_input"
                      class="d-inline-block mt-4"
                      label="Add an 'Other' Answer Option or Comment Field"
                      dense
                    ></v-checkbox>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditQuestion(1)"
            >
              Thêm
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditQuestion(2)"
            >
              Cập nhập
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogQuestion = false"
            >
              Đóng
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import EditorBalloon from "../../../../components/ckeditor5/EditorBalloon";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import { mapState } from "vuex";
export default {
  name: "FormQuestion",
  components: {
    Loading: () => import("vue-loading-overlay"),
    EditorBalloon,
    MultipleChoiceType: () =>
      import("@/view/pages/survey2/Question/MultipleChoiceType"),
    SingleChoiceType: () =>
      import("@/view/pages/survey2/Question/SingleChoiceType"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_call_api: false,
      title_input: "",
      question_types: [
        { label: "Short Answer", value: 1 },
        { label: "Single Choice", value: 2 },
        { label: "Star Rating", value: 3 },
        { label: "Multiple Choice", value: 4 },
        { label: "Date", value: 5 },
      ],
      value_question_types: {
        SHORT_ANSWER: 1,
        SINGLE_CHOICE: 2,
        STAR_RATING: 3,
        MULTIPLE_CHOICE: 4,
        DATE: 5,
      },
      type_input: null,
      is_required_input: true,
      optionAnswerMultipleChoice: [
        { option: "" },
        { option: "" },
        { option: "" },
      ],
      optionAnswerSingleChoice: [
        { option: "" },
        { option: "" },
        { option: "" },
      ],
      scale_input: null,
      option_group_id: null,
      question_id: null,
      other_text_option_input: false,
    };
  },
  computed: {
    ...mapState({
      option_groups: (state) => state.surveyQuestionStore.option_groups,
      questions: (state) => state.surveyQuestionStore.questions,
      question: (state) => state.surveyQuestionStore.question,
      question_number: (state) => state.surveyQuestionStore.question_number,
    }),

    dialogQuestion: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
    survey_id() {
      return this.$route.params.id;
    },
  },
  watch: {
    dialogQuestion(val) {
      if (val) {
        this.setDataForm();
      }
    },
    type_input() {
      this.option_group_id = null;
    },
    option_group_id(val) {
      if (val !== null) {
        let option = this.option_groups.filter(
          (option) => option.id === val
        )[0];
        if (this.type_input === 2) {
          this.optionAnswerSingleChoice = option.choices.map((choice) => {
            return { option: choice };
          });
        }
        if (this.type_input === 4) {
          this.optionAnswerMultipleChoice = option.choices.map((choice) => {
            return { option: choice };
          });
        }
      }
    },
  },
  mounted() {},
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.title_input = "";
        this.type_input = null;
        this.is_required_input = true;
        this.scale_input = null;
        this.option_group_id = null;
        this.other_text_option_input = false;
        this.setDefaultOption();
      } else {
        this.setDefaultOption();
        this.question_id = this.question.id;
        this.title_input = this.question.title;
        this.type_input = this.question.type;
        this.is_required_input = this.question.is_required;
        this.scale_input = this.question.scale;
        this.other_text_option_input = this.question.allow_other_text === 1;
        this.option_group_id = this.question.option_group_id;
        if (
          this.question.type === this.value_question_types.SINGLE_CHOICE ||
          this.question.type === this.value_question_types.MULTIPLE_CHOICE
        ) {
          let option = this.question.option.map((e) => {
            return { option: e };
          });
          if (this.question.type === this.value_question_types.SINGLE_CHOICE) {
            this.optionAnswerSingleChoice = option;
          }
          if (
            this.question.type === this.value_question_types.MULTIPLE_CHOICE
          ) {
            this.optionAnswerMultipleChoice = option;
          }
        }
      }
    },
    setDefaultOption() {
      this.optionAnswerMultipleChoice = [
        { option: "" },
        { option: "" },
        { option: "" },
      ];
      this.optionAnswerSingleChoice = [
        { option: "" },
        { option: "" },
        { option: "" },
      ];
    },
    validateData() {
      let flat = true;
      if (this.title_input === "" || this.title_input == null) {
        this.$toasted.error("Chưa nhập tiêu đề câu hỏi !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.type_input === "" || this.type_input == null) {
        this.$toasted.error("Chưa chọn loại câu hỏi !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }

      if (this.type_input === this.value_question_types.STAR_RATING) {
        if (this.scale_input == null) {
          this.$toasted.error("Chưa chọn giá trị scale !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }

      if (
        this.type_input === this.value_question_types.SINGLE_CHOICE ||
        this.type_input === this.value_question_types.MULTIPLE_CHOICE
      ) {
        // if (this.option_group_id == null) {
        //   this.$toasted.error('Chưa chọn mẫu đáp án !!', {theme: "toasted-primary", position: "top-right", duration: 4000});
        //   flat = false;
        // }
        if (this.type_input === this.value_question_types.MULTIPLE_CHOICE) {
          if (this.optionAnswerMultipleChoice.length < 2) {
            this.$toasted.error("Phải có tối thiểu 2 option !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
        }
        if (this.type_input === this.value_question_types.SINGLE_CHOICE) {
          if (this.optionAnswerSingleChoice.length < 2) {
            this.$toasted.error("Phải có tối thiểu 2 option !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            flat = false;
          }
        }
      }

      return flat;
    },
    btnAddOrEditQuestion(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        title: this.title_input,
        type: this.type_input,
        option: this.getOptionQuestionByType(this.type_input),
        is_required: this.is_required_input ? 1 : 0,
      };
      if (this.type_input === this.value_question_types.STAR_RATING)
        data.scale = this.scale_input;
      if (
        this.type_input === this.value_question_types.SINGLE_CHOICE ||
        this.type_input === this.value_question_types.MULTIPLE_CHOICE
      ) {
        if (this.option_group_id != null) {
          data.option_group_id = this.option_group_id;
        }
        data.allow_other_text = this.other_text_option_input ? 1 : 0;
      }
      vm.is_call_api = true;
      if (type === 1) {
        data.offset = this.questions.length + 1;
        ApiService.post(
          "prep-app/survey/" + this.survey_id + "/questions",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              // this.$store.commit('surveyQuestionStore/pushItemToQuestions', data);
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataQuestion");
              vm.is_call_api = false;
              vm.dialogQuestion = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
        vm.is_call_api = false;
        this.dialogQuestion = false;
      } else {
        ApiService.put(
          "prep-app/survey/" +
            this.survey_id +
            "/questions/" +
            this.question_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataQuestion");
              vm.is_call_api = false;
              vm.dialogQuestion = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
        vm.is_call_api = false;
        this.dialogQuestion = false;
      }
    },
    getOptionQuestionByType(type) {
      let option = [];
      if (type === this.value_question_types.MULTIPLE_CHOICE) {
        option = this.optionAnswerMultipleChoice.map((e) => e.option);
      }
      if (type === this.value_question_types.SINGLE_CHOICE) {
        option = this.optionAnswerSingleChoice.map((e) => e.option);
      }
      return option;
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style></style>
